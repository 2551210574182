import React from 'react';
import SearchBar from './Components/SearchBar';
import './App.css';
import Data from "./Data.json";

function App() {
  return (
    <div className="App">
      <SearchBar placeholder="Suchbegriff eingeben ..." data={Data} />
    </div>
  );
}

export default App;
