import React, {useState} from 'react';
import './SearchBar.css';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Pdf from "./files/Alle_BerufeaAnzeigen.pdf";


function onResumeClick() {
    window.open(Pdf);
}

function SearchBar({placeholder, data}) {
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");
    const [wordKey, setWordKey] = useState("");
    const [wordDoKpsBu, setWordDoKpsBu] = useState("");
    const [wordDoKps, setWordDoKps] = useState("");
    const [wordStatus, setWordStatus] = useState("");

    const handleFilter = (event) => {
        const searchWord = event.target.value
        setWordEntered(searchWord)
        const newFilter = data.filter((value) => {
            return value.gruppe.toLowerCase().includes(searchWord.toLowerCase())
        });

        if (searchWord === "") {
            setFilteredData([]);
            setWordKey("");
            setWordDoKpsBu("");
            setWordDoKps("");
            setWordStatus("");
        } else {
            setFilteredData(newFilter);
           // setWordKey(newFilter.key);
           
        }
    };

    const handleFilterEmpty = (event) => {
        const searchWord = " "
        setWordEntered(searchWord)
        const newFilter = data.filter((value) => {
            return value.gruppe.includes(searchWord)
        });

        if (searchWord === "") {
            setFilteredData([]);
            setWordKey("");
            setWordDoKpsBu("");
            setWordDoKps("");
            setWordStatus("");
        } else {
            setFilteredData(newFilter);
           // setWordKey(newFilter.key);
           
        }
    };

    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
        setWordKey("");
        setWordDoKpsBu("");
        setWordDoKps("");
        setWordStatus("");
    }

    const dataFinalResults  = (event) => {
        
        var dataName        = event.currentTarget.getAttribute("data-name");
        var dataIndexNumber = event.currentTarget.getAttribute("data-index-number");
        var dataDoKpsBu     = event.currentTarget.getAttribute("data-do_kps_bu");
        var dataDoKps       = event.currentTarget.getAttribute("data-do_kps");
        var dataStatus       = event.currentTarget.getAttribute("data-status");

        setWordEntered(dataName + " ("+ dataStatus +") ");
        setWordKey(dataIndexNumber);
        setWordDoKpsBu(dataDoKpsBu);
        setWordDoKps(dataDoKps);
        setWordStatus(dataStatus);
        // console.log(`name:  ${dataName}`);
        // console.log(`key:  ${dataIndexNumber}`);

        setFilteredData([]);

    }

  return (
    <div className="search">
        {/* <div>
            <h1 className="text-center">Hier steht eine Headline</h1>
            <p className="text-center">Ipsum Dolor meliore persecuti Aufschnitt mel. Te oratio utamur vix.<br/> Schneewittchen eloquentiam eu per. Principes complectitur Müller Rice no. His illud moderatius ut, Bahnhof pro omnis minim epicurei,<br/> natum Die Ärzte mel ei. Sea purto singulis Die unendliche Geschichte</p>
        </div> */}
        <div className="mainbox mt-60">

        
            <div className="searchInputs">
                <div className="searchIcon">
                    <SearchIcon /> 
                </div>
                <input type="text" placeholder={placeholder} value={wordEntered} onChange={handleFilter}/>
                <div className="searchIcon">
                    {wordEntered.length === 0 ? (
                    ""
                    ) : (
                    <CloseIcon id="clearBtn" onClick={clearInput} />
                    )}
                    
                </div>
                
                
            </div>
            {/* <div onClick={handleFilterEmpty} className="showAll">
                <a>Alle Berufe anzeigen</a>
            </div> */}
            <div className="showAll">
                <a onClick={onResumeClick}>Alle Berufe anzeigen</a>
            </div>
            {filteredData.length !== 0 && (   
            <div className="dataResults">
                {filteredData.map((value, index) => {
                    return <a className="dataItem" href="#" onClick={dataFinalResults} data-name={value.gruppe} data-index-number={value.key} data-do_kps_bu={value.do_kps_bu} data-do_kps={value.do_kps} data-status={value.status} key={value.key}> 
                                {/* {value.heading === 'h' ? (
                                <p key={value.key}><b>{value.gruppe} ({value.status})</b></p>
                                ) : (
                                   <p key={value.key}>{value.gruppe} ({value.status})</p>
                                )} */}
                                 <p key={value.key} className={value.color}>{value.gruppe} ({value.status})</p>
                            </a>;
                })}
            </div>
            
            )}
        </div>

        {wordKey.length !== 0 && ( 
        <div className="grid-container">
            {/* <div className="grid-item heading">Key</div>
            <div className="grid-item heading">Gruppe</div>
            <div className="grid-item heading">DO KSP und BU</div>  
            <div className="grid-item heading">DO KSP</div>
            <div className="grid-item">{wordKey}</div>
            <div className="grid-item">{wordEntered}</div>
            <div className="grid-item">{wordDoKpsBu}</div>  
            <div className="grid-item">{wordDoKps}</div> */}
        </div>
         )}

    </div>

  )
}

export default SearchBar;
